<template>
  <div class="demo">
    <!-- banner图片 -->
    <div class="banner">
      <div class="de">
        <div class="deText">精选案例</div>
      </div>
    </div>
    <!-- 芳园村 -->
    <div class="sec1">
      <img src="../../assets/index/demoOne.png" class="img" />
      <div class="secRight">
        <div class="srTit">海口市美兰区演丰镇芳园村</div>
        <div class="srTit srTits">智慧景区文旅项目</div>
        <div class="rBox">
          <div class="rbox">BOT智能科技结合海南省中国移动打造5G智慧美丽乡村，用科技建设海口市美兰区演丰镇芳园艺术文化村，用科技赋能解决园区游客吃、住、玩、购，并铺设5G智慧灯杆加入AI分布式视频监控系统，保障景区人员、车辆、资源的安全可控。整个园区所有一切事、物、人、车通过智能前端收集后，都汇总在云端进行AI大数据分析，通过一系列的智能化运营，实现芳园村数据统计平台，实时掌控园区的一举一动。芳园村是以河、塘、田、林为环境特色，新型旅游发展及乡村建设的可持续发展模式，是跨产业、多功能的综合规划，是多业态搭建业务结构的综合运营，强调乡村的休闲旅游功能从单一的观光功能转变为集会议展览、艺术品拍卖、研学教育、农事体验、田园民宿、观光旅游、休闲度假等于一体的复合功能，注重产业与科技的融合创新。</div>
        </div>
      </div>
    </div>
    <div class="sec2">
      <div class="seBanner"></div>
      <div class="se2Box">
        <div class="bTit">一带一心四区</div>
        <div class="bText">一带：红树林生态体验带（互联网生态旅游）</div>
        <div class="bText">一心：综合服务中心（互联网文化旅游）</div>
        <div class="bText">四区：互联网产业园区、互联网社区、互联网配套服务区、互联网孵化服务区。</div>
        <div
          class="bText"
        >项目毗邻演丰镇镇区，且紧邻镇区的住宅用地和商住用地，项目主要入口处则紧挨着社会停车场用地，此处应作为未来衔接演丰镇与外界的一个重要门户来进行打造。演丰镇镇区以互联网产业为发展核心，而本项目位于环绕半个镇区的红树林生态体验带上，可依托此条生态体验带将本项目发展为演丰镇镇区的“后花园”。</div>
        <div class="bTit">坚持以政府为主导，以产业为支撑的村庄发展模式</div>
        <div
          class="bText"
        >发挥政府美丽乡村建设主导作用，把农村建设纳入各级政府中长期计划、年度计划和各部门有关计划中。把村庄产业发展放在首要位置，乡村振兴必须以产业经济为支撑，根据村庄资源状况，积极发展特色产业，培育独具特色的优势经济，提高经济水平，才能有效保证各项建设。</div>
        <div class="bTit">规划背景及目标</div>
        <div
          class="bText"
        >中共中央十九大报告提出乡村振兴、健康中国、生态文明战略。指明：实施乡村振兴战略，是决胜全面建成小康社会、全面建设社会主义现代化国家的重大历史任务，是现时代“三农”工作的总抓手。2004年至2020年，中央发布的以“三农”（农业、农村、农民）为主题的中央一号文件，强调了“三农”问题在中国的社会主义现代化时期“重中之重”的地位。结合国土空间规划编制在县域层面基本完成村庄布局工作，有条件的村可结合实际单独编制村庄规划，做到应编尽编，实现村庄建设发展有目标、重要建设项目有安排、生态环境有管控、自然景观和文化遗产有保护、农村人居环境改善有措施。用科技来规划以满足村民生产、生活的需要，以现状道路为基础，结合乡村旅游发展的要求，建立结构合理、等级分明、功能明确、密度适中的道路系统。规划路网与居民点布局相结合，满足村庄的内外交通需要。合理安排停车场和配套道路交通设施。倡导绿色交通理念，配套自行车慢行交通系统，道路断面设计应适宜游人沿路步行参观。</div>
      </div>
    </div>
    <div class="sec3">
      <div class="se3Container">
        <div class="se3Box">
          <div class="s3Left">
            <div class="s3Time">2019~2022</div>
            <div class="s3Tit">海口市总体规划</div>
          </div>
          <div
            class="s3Right"
          >本项目在“多规合一”中，规划用地多为IV级保护林地和基本农田，其次为乡村建设用地、其他农用地、一般耕地、园地、河流湖泊水面、特殊用地、公路用地和自然保留地。</div>
        </div>
      </div>
      <div class="se3Container">
        <div class="se3Box">
          <div class="s3Left">
            <div class="s3Time">2023~2027</div>
            <div class="s3Tit">海口江东新区总体规划</div>
          </div>
          <div class="s3Right">项目地块在江东新区总体规划中处于东寨港国家自然保护区与中部绿心生态界面，用地性质主要为村庄建设用地与生态绿地。</div>
        </div>
      </div>
      <div class="se3Container">
        <div class="se3Box">
          <div class="s3Left">
            <div class="s3Time">2027~2030</div>
            <div class="s3Tit">海口市演丰镇总体规划修编</div>
          </div>
          <div class="s3Right">项目地块在演丰镇总体规划中的用地性质主要为其他农林用地、基本农田、村庄用地、旅游用地和特殊用地。</div>
        </div>
      </div>
      <div class="se3Container">
        <div class="se3Box">
          <div class="s3Left">
            <!-- <div class="s3Time">2027~2030</div> -->
            <div class="s3Tit" style="margin-top:0.12rem">海口市东寨港旅游区总体规划</div>
          </div>
          <div
            class="s3Right"
          >项目整体属于总规中农业体验型村庄，重点打造传统农业特色风貌，结合生态示范性种植业及特色林木种植，适当发展参与式绿色农产品种植、认养及采摘，传统农具加工等农家民俗活动。</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  height: 8em;
  background: url("../../assets/index/demoBanner.png") no-repeat center center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  .de {
    margin-top: 5.2em;
    width: 11em;
    height: 1.2em;
    background: rgba(255, 144, 0, 0.6);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .deText {
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      color: #ffffff;
      margin-right: 0.66rem;
    }
  }
}
.sec1 {
  width: 100%;
  padding: 1.2rem 0px;
  display: flex;
  .img {
    width: 7.83rem;
    height: 9.83rem;
    z-index: 4;
  }
  .secRight {
    flex: 1;
    text-align: right;
    .srTit {
      width:4.52rem;
      margin-top: 2.26rem;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      color: #333333;
      margin-left:3.18rem;
    }
    .srTits {
      margin-top: 0.1rem;
    }
    .rBox {
      margin-top: 0.8rem;
      margin-left: -1.03rem;
      width: 9rem;
      height: 5rem;
      background-color: #ff9000;
      position: relative;
      .rbox {
        width: 100%;
        height: 100%;
        background-color: #fff;
        position: absolute;
        top: 0.18rem;
        right: 0.18rem;
        z-index: 9;
        border: 0.02rem solid #000000;
        padding: 1.35rem 0.59rem;
        text-align: justify;
        font-size: 0.18rem;
        line-height: 0.3rem;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
.sec2 {
  width: 100%;
  background-color: #fff;
  .seBanner {
    width: 100%;
    height: 6em;
    background: url("../../assets/index/demoTwo.png") no-repeat center center;
    background-size: cover;
  }
  .se2Box {
    width: 12rem;
    margin: 0 auto;
    color: #333333;
    text-align: left;
    .bTit {
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      padding-bottom: 0.24rem;
      border-bottom: 1px solid #999;
      margin-top: 0.79rem;
      margin-bottom: 0.4rem;
    }
    .bText {
      font-size: 0.18rem;
      line-height: 0.18rem;
      font-weight: 400;
      line-height: 0.3rem;
      text-align: justify;
    }
  }
}
.sec3 {
  width: 100%;
  background-color: #fff;
  padding: 1.2rem 0px;
  .se3Container {
    width: 100%;
    border-top: 1px solid #999;
    box-sizing: border-box;
    &:last-of-type {
      border-bottom: 1px solid #999;
    }
    .se3Box {
      width: 12rem;
      margin: 0 auto;
      padding: 0.6rem 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .s3Left {
        width: 4rem;
        text-align: left;
        .s3Time {
          font-size: 0.48rem;
          line-height: 0.48rem;
          font-weight: 400;
          color: #ff9000;
        }
        .s3Tit {
          font-size: 0.24rem;
          line-height: 0.14rem;
          font-weight: bold;
          color: #333333;
          margin-top: 0.23rem;
        }
      }
      .s3Right {
        width: 8.12rem;
        margin-left: 0.65rem;
        text-align: justify;
        font-size: 0.18rem;
        line-height: 0.18rem;
        font-weight: 400;
        color: #333333;
        line-height: 0.3rem;
      }
    }
  }
}
</style>
